import styled from 'styled-components';

const FormWrapper = styled.div`
  padding: 24px;
  padding-top: 0;
  padding-bottom: 0;

  .ant-form-item-label {
    white-space: normal;  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }

  .form-buttons {
    .ant-btn {
      margin-top: 8px;
      margin-right: 8px;
    }
  }
`;

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 6 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 18 },
    lg: { span: 12 },
  },
};
export const heroSoftwareformItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 6 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
};


export const formItemVerticalLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
};

export const formItemLongLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 14 },
  },
};

export const integrationItemLongLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 14 },
  },
};

export const integrationDealStageItemLongLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 20 },
    lg: { span: 20 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 4 },
    lg: { span: 4 },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    md: {
      span: 18,
      offset: 6,
    },
    lg: {
      span: 12,
      offset: 4,
    },
  },
};
export const heroIntegrationtailFormItemLayout = {
  wrapperCol: {
    md: {
      span: 24,
    },
    lg: {
      span: 24,

    },
  },
};

export const integrationTailFormItemLongLayout = {
  wrapperCol: {
    md: {
      span: 12,
      offset: 6,
    },
    lg: {
      span: 12,
      offset: 6,
    },
  },
};

export const tailFormItemLongLayout = {
  wrapperCol: {
    md: {
      span: 18,
      offset: 6,
    },
    lg: {
      span: 18,
      offset: 6,
    },
  },
};

export default FormWrapper;
